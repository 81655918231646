@import "src/stylesheets/_variables.scss";

@import '~bootstrap/scss/bootstrap.scss';

body, html {
  width:100%; 
  overflow-x:hidden;
}

body{
  font:13px/1.3 'Poppins', 'museo-sans-rounded','Lucida Grande',sans-serif ;
  color: var(--font-color);
  background: var(--bg-color);
  font-weight: 400;
  letter-spacing:0.3px;
  overflow-x:hidden;
}


*{
  min-height: 0;
  min-width: 0;
}

a:not([href]):not([tabIndex]),
a:hover:not([href]):hover:not([tabIndex]),
a {
  color: var(--primary-color);
  cursor: pointer;
  text-decoration: none;

  &:hover{
    color: var(--primary-color-hover);
  }
}

button:focus {outline:0;}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Poppins', 'museo-sans-rounded','Lucida Grande', Arial, sans-serif;
  font-weight:300;
}

.h1, h1 {
  font-size:28px;
}

h2 {
  font-size:22px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px;
}

// TODO: Delete this style below?? 
.dropdown-menu {
  width:100%;
  background: #2e2e2e;
  color:#ffffff;
  border-radius:0px;
  top:95%;
  text-align:center;
  padding:0px;
  margin-top: 0 !important;

  a {
    color:#ffffff;
    font-size:12px;
    font-weight:300;
    text-transform:uppercase;
    padding:10px 0;

    &:hover {
      background:#3E3E3E;
    }

  }

  .nav-link:hover{
    color: inherit;
  }
  
  .nav-link.logout:hover{
    color: #F9AD00;
  }

  .active {
    background:#3E3E3E;
  }

  .logout {
    background:#3E3E3E;
    color:#F9AD00;
    font-size:12px;
    font-weight:300;
    text-transform:uppercase;
    padding:10px 0;

    &:hover {
      cursor: pointer;
    }
  }
}

/* Keyframes for the fade-in */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.animate,
.valuePosition {
  opacity:0;
  opacity: 1 \9; /*just in case ie*/
  -webkit-animation:fadeIn ease-in 1;
  -moz-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  animation-duration:1s;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}
 

.bottomButton {
  bottom: 15px;
  width: 100%;
  left: 0;
}

.badge-primary {
  background: #24B8F6;
  border-radius: 0;
  font-weight: normal;
}

.bg-white {
  background: white;
  height: 100%;
}

.btn {
  font-weight:700;
  border: 1px solid var(--primary-color-hover);
  border-radius: 10px!important;
  margin: 0 auto;
  display: table;
  font-size: 14px;
  color:#ffffff;
  background: var(--primary-color);
  padding:0.48rem 0.95rem;


  &.white {
    background: white;
    border: 0;
  }

  &.yellow {
    background: #FABA25;
    color:#ffffff !important;
    border: 0;
  }

  &.btn-primary {
    background: var(--primary-color);
    color:#ffffff !important;
    border: 1px solid var(--primary-color-hover);
    font-weight: 500;
    
    &:hover {
      border: 1px solid var(--primary-color-hover) !important;
      background: var(--primary-color-hover) !important;
    }
  }

  &.btn-primary-outline {
    background: transparent;
    color:var(--primary-color) !important;
    border: 1px solid var(--primary-color);
    font-weight: 500;
    
    &:hover {
      background: var(--primary-color-hover) !important;
      border-color: var(--primary-color-hover);
    }
  }

  &:hover {
    cursor:pointer;
    border: 1px solid #ffffff;
    color:#23B7B7 !important ;
    background: #ffffff !important;
  }
}

.life .btn, .life a.btn {
  color: #fff;
}

 

.alert {
  border: 0;
  color: white;
  border-radius: 20px;
  text-align: center;
  padding: 5px 30px !important;
  display: flex;
  justify-content: center;
}

.alert svg{
  position: absolute !important;
  left: 10px;
  top: 6px;

  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.alert-info {
  background: var(--primary-lighter);
}

.alert-success {
  background: var(--green);
}

.alert-danger {
  background: var(--red);
}

.alert-warning {
  background: var(--yellow);
}


.centeredLink {
  margin-top: auto;
  display: block;
  text-align: center;
  width: 100%;
  &:hover {
    text-decoration: underline!important;
  }
}

.thinText {
  font-weight:100;
}

.thickText {
  font-weight:600;
}

.smallLink {
  font-size:12px;
}

.d-inline-grid{
  display: inline-grid;
}

/* headlines with lines */
 

.decorated-divider span {
  position: relative; 
  z-index: 1;
  overflow: hidden;
  text-align: center;
  color:var(--accent-color);
  font-weight: 700;
}
.decorated-divider span:before, .decorated-divider span:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 48%;
  height: 1px;
  content: '\a0';
  background-color: var(--accent-color);
}
.decorated-divider span:before {
  margin-left: -50%;
  text-align: right;
} 

.decorated-divider span:after {
  margin-left: 2%;
  text-align: left;
} 

.form-group{
  margin: 1rem 0;
}

.fal, .far, .fas {
  font-family: "Font Awesome 6 Pro"!important;
}

.text-balance {
  text-wrap: balance;
}

.text-pretty {
  text-wrap: pretty;
}

:root,
.life {
  --bg-color: #F8F0F3;
  --bg-color-lighter: #FBF7F9;
  --loader-bg-color: #F8F0F3;

  --footer-bg-color: #ffffff;
  --footer-bg-color-apps: #FBF7F9;
  --footer-accent-color: #726267;
  --footer-font-color: #3E3C3A;
  --footer-font-color-hover: #000000;
  --footer-hr-color: #bbb1b5;

  --font-color: #000000;
  --title-color: #726267;
  --accent-color: #726267;
  --accent-color-lighter: #A38C94;
  --icon-color: #76739D;

  --nav-bg-color: #F8F0F3;
  --nav-active-color: #000000;
  --nav-color: #3E3C3A;

  --sidebar-bg-color: #A38C94;
  --sidebar-logout-btn: #726267;

  --profile-bg-color: #E8DCE1;
  --profile-bg-color-active: #A38C94;
  --profile-accent-color: #726267;
  --profile-font-color: #F8F0F3;

  --order-box-bg-color: #F3F3FB;
  --order-box-bg-accent-color: #726267;
  --order-badge-color: #A38C94;
  --order-box-icon-color: #76739D;

  --result-nav-bg-color: #726267;
  --result-nav-bg-color-active: #A38C94;

  --nextStep-bg-color: #FBF7F9;
  --female-report-box-bg-color: #FBF7F9;
  --female-report-border-color: #f5f5f5;

  --rating-bg: #E8DCE1;
  --rating-active-color: #726267;
  --rating-placeholder-color: #b6a1a8;

  --checkout-box-bg-color: #F5ECE7;
  --checkout-partner-box-bg-color: #F5ECE7;

  --box-bg-color: #FFFFFF;
  --box-bg-accent-color: #726267;
  --box-bg-accent-color-lighter: #927E85;

  --primary-color: #5474E5;
  --primary-color-hover: #4762c5;

  --default-color: #3E3C3A;
  --default-color-hover: #282625;
  --default-font-color: #ffffff;
  --default-font-color-hover: #ffffff;
  --default-outline-font-color: #000000;
  --default-outline-font-color-hover: #eeeeee;

  --improve-markers-bg-color: #FBF7F9;
  --guide-line-color: #D6C8CD;

  --green: #28C7BE;
  --green-lighter: #28c7bf33;
  --yellow: #FDC135;
  --yellow-lighter: #FDC13533;
  --red: #D02251;
  --red-lighter: #D0225133;
  --grey: #E3ECEF;

  --profile-gradient-color-1: #756ECD;
  --profile-gradient-color-2: #36F9FC;
}

.edge {
  --bg-color: #000000;
  --bg-color-lighter: #313131;
  --loader-bg-color: #000000;

  --footer-bg-color: #000000;
  --footer-bg-color-apps: #313131;
  --footer-accent-color: #D4D5D8;
  --footer-font-color: #D4D5D8;
  --footer-font-color-hover: #ffffff;
  --footer-hr-color: #5a5a5a;

  --font-color: #ffffff;
  --title-color: #D4D5D8;

  --accent-color: #313131;
  --accent-color-lighter: #535353;
  --icon-color: #FB3B18;

  --nav-bg-color: #000000;
  --nav-active-color: #FB3B18;
  --nav-color: #ffffff;

  --sidebar-bg-color: #313131;
  --sidebar-logout-btn: #535353;

  --profile-bg-color: #313131;
  --profile-bg-color-active: #000000;
  --profile-accent-color: #313131;
  --profile-font-color: #F8F0F3;

  --order-box-bg-color: #000000;
  --order-box-bg-accent-color: #313131;
  --order-badge-color: #535353;
  --order-box-icon-color: #FB3B18;
  
  --result-nav-bg-color: #313131;
  --result-nav-bg-color-active: #535353;

  --nextStep-bg-color: #535353;
  --female-report-box-bg-color: #535353;
  --female-report-border-color: #535353;

  --rating-bg: #535353;
  --rating-active-color: #FB3B18;
  --rating-placeholder-color: #835145;

  --box-bg-color: #313131;
  --box-bg-accent-color: #313131;
  --box-bg-accent-color-lighter: #000000;

  --primary-color: #FB3B18;
  --primary-color-hover: #ab432c;

  --default-color: #000;
  --default-color-hover: #d3d1d1;
  --default-font-color: #000000;
  --default-font-color-hover: #000000;
  --default-outline-font-color: #eeeeee;
  --default-outline-font-color-hover: #000000;

  --improve-markers-bg-color: #535353;
  --guide-line-color: #666;

  --green: #28C7BE;
  --green-lighter: #28c7bf33;
  --yellow: #FDC135;
  --yellow-lighter: #FDC13533;
  --red: #D02251;
  --red-lighter: #D0225133;
  --grey: #4b4a4a;

  
  --profile-gradient-color-1: #FCB026;
  --profile-gradient-color-2: #FB3B18;

  --checkout-box-bg-color: #000;
  --checkout-partner-box-bg-color: #eeeeee;
}


@import "src/stylesheets/_fontello.scss"; 
@import "src/stylesheets/_loader.scss";
@import "src/stylesheets/_general.scss";
@import "src/stylesheets/_home.scss";
@import "src/stylesheets/_tabbedarea.scss";
@import "src/stylesheets/_scrollingnav.scss";
@import "src/stylesheets/_categorydetails.scss"; 
@import "src/stylesheets/_healthoverview.scss";
@import "src/stylesheets/_results.scss";
@import "src/stylesheets/_myaccount.scss";
@import "src/stylesheets/_resultexport.scss";
@import "src/stylesheets/_footer.scss"; 
@import "src/stylesheets/_edgeOveride.scss";
@import "src/stylesheets/_demo.scss";
@import "src/stylesheets/_stripe.scss";
@import "src/stylesheets/_partnerClients.scss";
@import "src/stylesheets/_partnerForm.scss";
@import "src/stylesheets/_partnerHeader.scss";
@import "src/stylesheets/_partnerManagement.scss";
@import "src/stylesheets/_partnerOrders.scss";
@import "src/stylesheets/_partnerResults.scss";
@import "src/stylesheets/_partnerTables.scss";
@import "src/stylesheets/_partnerShop.scss";
@import "src/stylesheets/_partnerSingleProduct.scss";
@import "src/stylesheets/_bespokeTests.scss";
@import "src/stylesheets/_bespokeTestsRedesign.scss";
@import "src/stylesheets/_shop.scss";
@import "src/stylesheets/_checkout.scss"; 
@import "src/stylesheets/_activation.scss"; 
@import "src/stylesheets/_activation.scss";
@import "src/stylesheets/_femalehormones.scss";
@import "src/stylesheets/_hormonereport.scss";
@import "src/stylesheets/_gettingStarted.scss";
@import "src/stylesheets/_editSubscription.scss";
@import "src/stylesheets/_rating.scss";
@import "src/stylesheets/_font.scss";
